<template>
  <div>
    <div class="sport">
       <div class="sportimg">
                <img :src="bannerimg" style="width:100%" alt="">
       </div>
       <router-view style="height:100%"></router-view>
    </div>
  </div>
</template>

<script>
import {getbannerdetail} from '@/http/api/user.js'
export default {
   data(){
     return{
          bannerimg:'',
         
     }
   },
   mounted(){
this.getcompany()
   },
   created(){

   },
   methods:{
     //获取公司简介
    async getcompany() {
      let params = {
        barId:9,
      };
      const data = await getbannerdetail(params);
      this.bannerimg='http://36.152.65.166:10008'+data.data.image
     
      
    },
   }
};
</script>

<style>

</style>